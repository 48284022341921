<template>
	<div class="generatechart" data-aos="slide-down" data-aos-delay="0">
		<div style="width: 100%;height: 100%;display: flex;">
			<iframe style="width: 100%; flex:1;" src="https://osscold.baozhida.cn/Tool/DataTool.html" allowfullscreen="allowfullscreen" frameborder="0" data-id="1"></iframe>
		    <!-- <iframe style="width: 100%; height: 800px;" src="https://osscold.baozhida.cn/Tool/DataTool.html" allowfullscreen="allowfullscreen" frameborder="0" data-id="1"></iframe> -->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		created() {
			// window.open('https://osscold.baozhida.cn/Tool/DataTool.htm','_blank')
		},
		mounted() {
		}
		// created() {
		// 	this.$route.path('https://www.baidu.com/')
		// }
	}
</script>

<style lang="scss">
	.generatechart{
		height: 100vh;
	}
</style>
